<template>
  <div
    class="form-login d-flex justify-content-center align-items-center flex-column beforeFormLogin"
    v-if="not_login && ListSettingShop && InfoContent"
  >
    <div class="logo img-logo-header">
      <img
        v-if="logoShop"
        :src="logoShop"
        width="100%"
        height="100%"
        style="object-fit: contain; width: 100%; height: 100%"
      />
    </div>
    <div class="row mb-3 px-3 px-sm-5">
      <div class="col-lg-6 col-sm-12 spborder" style="margin-top: 50px">
        <div class="sp-p0">
          <form v-on:submit.prevent="submitLogin(shopId)">
            <div class="form-title">ログイン後購入画面に移ります</div>
            <p class="sp-only login-title">メールアドレスでログイン</p>
            <b-alert show variant="danger" v-if="alertMessage">{{
              message
            }}</b-alert>
            <div class="d-flex flex-column form-input">
              <label for="email" style="font-size: 16px"
                >ユーザー名またはメールアドレス</label
              >
              <input
                v-model="email"
                type="email"
                name="email"
                id="email"
                placeholder="メールアドレス"
                style="color: black"
              />
            </div>
            <div class="d-flex flex-column form-input">
              <label for="password" style="font-size: 16px"
                >パスワード<router-link
                  :to="{
                    name: $route.params.shopId
                      ? 'forgot password'
                      : 'forgot password domain',
                  }"
                  class="color-blue"
                >
                  <a class="" style="font-size: 15px">パスワードを忘れた方</a>
                </router-link></label
              >
              <div class="row ml-0 mr-0 flex-nowrap">
                <input
                  v-if="isshowPassword === false"
                  v-model="password"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="パスワード"
                  class="col-11"
                />
                <input
                  v-else-if="isshowPassword === true"
                  v-model="password"
                  name="password"
                  id="password"
                  placeholder="パスワード"
                  class="col-11"
                />
                <span
                  v-if="isshowPassword === false"
                  class="input-group-text col-auto px-2"
                  id="basic-addon2"
                  style="
                    height: 50px;
                    margin: 5px 0px 20px;
                    cursor: pointer;
                    color: black;
                  "
                  @click="showPassword"
                  ><i class="fas fa-eye" style="width: 17.5px"></i
                ></span>
                <span
                  v-else-if="isshowPassword === true"
                  class="input-group-text col-auto px-2"
                  id="basic-addon2"
                  style="
                    height: 50px;
                    margin: 5px 0px 20px;
                    cursor: pointer;
                    color: black;
                  "
                  @click="showPassword"
                >
                  <i class="fas fa-eye-slash"></i>
                </span>
              </div>
            </div>
          </form>
          <div class="text-center mb-5" style="font-size: 16px">
            <span
              >ログインすることにより、当社の
              <a
                class="text-decoration-underline"
                style="color: black; text-decoration: underline"
                v-b-modal.modal-terms
                v-if="htmlTerms"
                >利用規約</a
              >
              <span v-else>利用規約</span>
              および
              <a
                class="text-decoration-underline"
                style="color: black; text-decoration: underline"
                v-b-modal.modal-policy
                v-if="htmlPolicy"
                >プライバシー規約</a
              >
              <span v-else>プライバシー規約</span>
              約に同意したとみなされます。</span
            >
          </div>
          <!-- <div class="line-text"><span>他のIDでログイン</span></div> -->
          <!--<div class="container login-sns">
            <div class="row">
              <div
                class="col-lg-12 mx-auto"
                style=""
                v-if="isCheckFacebook === 1"
              >
                <a
                  :href="`${urlBackend}/auth/facebook?shop_id=${shop_id}&slag_token=${slagToken}&isLogin=${1}`"
                >
                  <button class="btn-common btn-facebook">
                    <i class="fab fa-facebook-square size-icon"></i>
                    Facebook でログインする
                  </button>
                </a>
              </div>
              <div class="col-lg-12" v-if="isCheckTwitter === 1">
                <a>
                  <button
                    class="btn-common btn-twitter"
                    v-on:click.prevent="loginTwitter()"
                  >
                    <i class="fab fa-twitter size-icon"></i>
                    Twitter でログインする
                  </button>
                </a>
              </div>
              <div class="col-lg-12" v-if="isCheckLine === 1">
                <button
                  class="btn-common btn-line"
                  v-on:click.prevent="loginLine()"
                >
                  <i class="fab fa-line size-icon"></i>
                  <i class="bi bi-activity"></i>
                  Line でログインする
                </button>
              </div>
              <div class="col" v-if="isCheckAmazon === 1">
                <a
                  :href="`${urlBackend}/auth/amazon?shop_id=${shop_id}&slag_token=${slagToken}&isLogin=${1}`"
                >
                  <button class="btn-common btn-amazon">
                    <i class="fab fa-amazon size-icon"></i>
                    Amazon でログインする
                  </button>
                </a>
              </div>
            </div>
          </div>-->
          <div class="remember-form">
            <input
              v-model="remember_me"
              type="checkbox"
              name="remember"
              id="remember"
            />
            <label class="ml-1" for="remember">ログインしたままにする</label>
          </div>
          <div class="btn-submit mt-3">
            <button
              v-on:click.prevent="submitLogin(shopId)"
              :disabled="isLoading"
              style="width: 50%"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              <span>ログイン</span>
            </button>
          </div>
          <br />
          <!-- <div class="text-center">
          <span
            ><a href="\" style="color: black">よくあるご質問</a> &ensp;
            <a href="\" style="color: black">お問い合わせ</a> &ensp;
            <a href="\" style="color: black">会社概要</a> &ensp;
            <a href="\" style="color: black">プライバシーポリシー</a> &ensp;
            <a href="\" style="color: black">利用規約</a> &ensp;
            <a href="\" style="color: black">特定商取引法</a>
            に基づく表記</span
          >
        </div> -->
        </div>
        <div>
          <span class="title2">初めての方ですか？</span>
          <router-link
            :to="{
              name: $route.params.shopId
                ? 'Before SignUp'
                : 'Before SignUp domain',
              params: { userId: $route.params.idContent },
            }"
          >
            <div class="btn-register" style="">
              <button>今すぐ新規会員登録</button>
            </div>
          </router-link>
        </div>
      </div>
      <div class="col-lg-6">
        <div
          class="col-sm-12"
          style="margin-top: 50px; border: 1px solid #bfc0c4; padding: 0px"
          v-if="InfoContent"
        >
          <div class="pt-4 pb-4" style="background: #424b55">
            <h6 class="text-center customTitle">お申込内容</h6>
          </div>
          <div style="background: white" class="p-3">
            <div class="mt-2 mb-2">
              <CRow>
                <CCol sm="12" class="customTitleContent">
                  {{ InfoContent.title || InfoContent.content_title }}
                </CCol>
                <CCol
                  sm="12"
                  class="customAmountContent"
                  v-if="infoSubription.isSubscription"
                >
                  <div>
                    金額：¥
                    {{
                      InfoContent.product_price
                        ? Math.round(
                            Number(InfoContent.product_price)
                          ).toLocaleString("ja")
                        : 0
                    }}
                  </div>
                  <div>
                    請求間隔：{{ infoSubription.addBill
                    }}{{ infoSubription.textChange }}
                  </div>
                </CCol>
                <CCol v-else sm="12" class="customAmountContent">
                  ¥{{
                    Math.round(
                      Number(InfoContent.product_price)
                    ).toLocaleString("ja")
                  }}
                </CCol>
              </CRow>
            </div>
            <div class="mt-5 mb-5">
              <CRow>
                <CCol
                  sm="12"
                  :class="parseInt(InfoContent.fee) ? '' : 'display-hide'"
                  ><span style="font-size: 18px">送料・手数料</span
                  ><span style="margin: 0 0 0 1em; font-size: 18px"
                    >&nbsp;&nbsp;&nbsp; ¥
                    {{ InfoContent.fee ? parseInt(InfoContent.fee) : 0 }}</span
                  >
                </CCol>
                <CCol
                  sm="12"
                  :class="parseInt(InfoContent.taxRate) ? '' : 'display-hide'"
                  ><span style="font-size: 18px"
                    >うち{{ InfoContent.taxRate }}%対象</span
                  ><span style="margin: 0 0 0 1em; font-size: 18px"
                    >&nbsp;&nbsp;&nbsp; ¥{{
                      Math.round(
                        Number(InfoContent.totalPricePayment)
                      ).toLocaleString("ja")
                    }}
                    （消費税 : ¥{{
                      Math.round(Number(InfoContent.tax)).toLocaleString("ja")
                    }}）
                  </span>
                </CCol>
                <CCol
                  sm="12"
                  v-if="
                    infoSubription.isSubscription &&
                    infoSubription.trialPeriod > 0
                  "
                  ><span style="font-size: 18px"> お試し期間</span
                  ><span style="margin: 0 0 0 2em; font-size: 18px"
                    >&nbsp;&nbsp;&nbsp;
                    {{ infoSubription.trialPeriod }}日間</span
                  >
                </CCol>
                <CCol
                  sm="12"
                  v-if="
                    infoSubription.isSubscription &&
                    infoSubription.billingCount > 0
                  "
                  ><span style="font-size: 18px"> 請求回数</span
                  ><span style="margin: 0 0 0 3em; font-size: 18px"
                    >&nbsp;&nbsp;&nbsp;
                    {{ infoSubription.billingCount }}回</span
                  >
                </CCol>
              </CRow>
            </div>
          </div>
          <div style="background: #e6e8ea" class="p-3">
            <div class="" style="background: #e6e8ea">
              <CCol sm="12" class="px-0 d-flex justify-content-between">
                <span style="font-size: 18px">{{
                  !Number(InfoContent.tax) ? "総額" : "合計"
                }}</span>
                <span
                  v-if="infoSubription.isSubscription"
                  style="font-size: 23px"
                  >&nbsp;&nbsp;&nbsp;¥
                  {{
                    Math.round(
                      Number(InfoContent.totalPricePayment)
                    ).toLocaleString("ja")
                  }}
                  / {{ InfoContent.sale_basic.column[0].addBill
                  }}{{ infoSubription.textChange }}</span
                >
                <span v-else class="totalAmount"
                  >&nbsp;&nbsp;&nbsp;¥
                  {{
                    Math.round(
                      Number(InfoContent.totalPricePayment)
                    ).toLocaleString("ja")
                  }}</span
                >
              </CCol>
              <CCol
                sm="12"
                class="px-0 d-flex justify-content-between"
                v-if="
                  infoSubription.isSubscription && infoSubription.initialCost
                "
              >
                <span style="font-size: 18px; font-weight: bold">初期費用</span>
                <span class="totalAmount"
                  >&nbsp;&nbsp;&nbsp;
                  {{
                    Math.round(
                      Number(infoSubription.initialCost * 1)
                    ).toLocaleString("ja")
                  }}円（税込）
                </span>
              </CCol>
            </div>
          </div>
          <div class="col-sm-12 px-0">
            <div
              class=""
              style="
                background: white;
                padding: 15px 10px;
                font-size: 15px;
                color: #666;
              "
            >
              <span style=""
                >分割決済の場合、分割手数料が別途かかります。
                分割手数料はカード会社によって異なります。</span
              >
            </div>
          </div>
          <div class="w-100">
            <b-modal id="modal-terms" hide-header hide-footer class="w-100">
              <div class="d-block text-center"></div>
              <div class="d-flex justify-content-center">
                <div class="w-100 customImg" v-html="htmlTerms" />
              </div>
            </b-modal>
          </div>
          <div class="w-100">
            <b-modal id="modal-policy" hide-header hide-footer class="w-100">
              <div class="d-block text-center"></div>
              <div class="justify-content-center">
                <div class="w-100 customImg" v-html="htmlPolicy" />
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
import no_image from "@/assets/img/logo_default.png";
import moment from "moment";

export default {
  name: "Login",
  data() {
    return {
      urlBackend: Constants.URL_BE_API,
      email: "",
      password: "",
      remember_me: false,
      shopId: null,
      alertMessage: false,
      message: "",
      shop_id: null,
      logoShop: null,
      totalPointUse: null,
      InfoContent: null,
      totalPointBonus: null,
      totalPrice: null,
      fee: null,
      totalPricePayment: null,
      totalOrder: null,
      tax: null,
      PriceContent: null,
      TotalPriceContent: null,
      dataActiveToken: null,
      isCheckFacebook: null,
      isCheckLine: null,
      isCheckTwitter: null,
      isCheckAmazon: null,
      slagToken: this.$route.query.token
        ? this.$route.query.token.split("?")[0]
        : "",
      isshowPassword: false,
      htmlTerms: null,
      htmlPolicy: null,
      isLoading: false,
      infoSubription: {
        isSubscription: false,
        textChange: "",
        initialCost: "",
        trialPeriod: "",
        billInternal: "",
        addBill: "",
        billingCount: "",
      },
      paymentType: Constants.PAYMENT_TYPE,
      token_user: localStorage.getItem(Constants.TOKEN_USER),
      is_login: localStorage.getItem(Constants.TOKEN_USER) ? true : false,
      not_login: false,
    };
  },
  async created() {
    const shopId = this.$route.params.shopId
      ? this.$route.params.shopId
      : localStorage.getItem(Constants.SHOP_ID);
    const expires_at_user = localStorage.getItem(Constants.EXPIRES_AT_USER);
    if (
      localStorage.getItem(Constants.TOKEN_USER) !== null &&
      new Date() <= new Date(expires_at_user)
    ) {
      const id = this.$route.params.idContent;
      await this.getDetailContentPayment({ shopId: shopId, id });
      // if (this.$route.params.shopId) {
      //   this.$router.push({
      //     name: "paymentBuy",
      //     params: {
      //       shopId: this.$route.params.shopId,
      //       id: this.$route.params.idContent,
      //     },
      //   });
      // } else {
      //   this.$router.push({
      //     name: "paymentBuy domain",
      //     params: {
      //       id: this.$route.params.idContent,
      //     },
      //   });
      // }
    } else {
      this.not_login = true;
    }
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId || null,
    });
    await this.$store.dispatch("getShopById", {
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId || null,
    });
    this.shop_id = localStorage.getItem(Constants.SHOP_ID);
    this.getGeneralByIdContent(this.shop_id);
    let content_id;
    if (
      this.$route.name.includes("payment") ||
      this.$route.name.includes("confirm_payment")
    ) {
      content_id = this.$route.params.id;
    } else {
      content_id = this.$route.params.idContent;
    }
    if (content_id) {
      this.getDetailContentPreSales({ shopId: this.shop_id, id: content_id });
    }
    if (
      localStorage.getItem(Constants.EMAIL_USER) !== null ||
      localStorage.getItem(Constants.EMAIL_USER) !== undefined
    ) {
      this.email = localStorage.getItem(Constants.EMAIL_USER);
    }
    const { params } = this.$route;
    this.shopId = params.shopId;
    // localStorage.setItem(Constants.SHOP_ID, this.shopId);
    const token = localStorage.getItem(Constants.TOKEN_USER);
    const userInfo = JSON.parse(
      localStorage.getItem(Constants.NORMAL_USER_INFO)
    );
    const expires_at = localStorage.getItem(Constants.EXPIRES_AT_USER);
    const isRemember = localStorage.getItem(Constants.IS_REMEMBER_USER);
    this.remember_me =
      new Date() <= new Date(expires_at) ? isRemember === "true" : false;
    if (
      token &&
      userInfo.shop_id == this.shopId &&
      userInfo.user_type == 4 &&
      isRemember === "true" &&
      new Date() <= new Date(expires_at)
    ) {
      this.$router.push({
        name: this.$route.params.shopId
          ? "content detail"
          : "content detail domain",
        params: {
          shopId: this.shopId,
          id: content_id,
        },
        query: { keyword: this.formdata.keyword },
      });
    }
  },
  computed: {
    ...mapGetters([
      "pageId",
      "ListSettingShop",
      "InfoContentPayment",
      "dataGeneral",
      "detailcontentPreSales",
      "detailShop",
      "detailStore",
      "modalShow",
    ]),
  },
  watch: {
    detailcontentPreSales() {
      if (Object.keys(this.detailcontentPreSales).length > 0) {
        if (
          !this.detailcontentPreSales[0].sale_basic ||
          !this.detailcontentPreSales[0].sale_basic.column
        ) {
          const shopId = this.$route.params.shopId
            ? this.$route.params.shopId
            : localStorage.getItem(Constants.SHOP_ID);
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "login shop",
              params: { shopId: shopId },
            });
          } else {
            this.$router.push({
              name: "login shop domain",
            });
          }
        }
        console.log(1);
        this.InfoContent = this.detailcontentPreSales[0];
        if (
          this.InfoContent.product_type != 3 &&
          (this.InfoContent.sale_basic.column[0].product_price === null ||
            !this.InfoContent.sale_basic.column[0].product_price)
        ) {
          const shopId = this.$route.params.shopId
            ? this.$route.params.shopId
            : localStorage.getItem(Constants.SHOP_ID);
          if (this.$route.params.shopId) {
            this.$router.push({
              name: "login shop",
              params: { shopId: shopId },
            });
          } else {
            this.$router.push({
              name: "login shop domain",
            });
          }
        }

        // change sentence
        this.InfoContent.content_title =
          this.$root.$refs.baseAuth.changeSentence(
            this.InfoContent.content_title
          );

        const tax = this.InfoContent.sale_basic.column[0].tax
          ? this.InfoContent.sale_basic.column[0].tax
          : this.InfoContent.tax_rate;
        this.InfoContent.taxRate = tax;
        this.InfoContent.product_price =
          this.InfoContent.sale_basic.column[0].product_price;

        if (
          this.InfoContent.sale_basic &&
          parseInt(this.InfoContent.sale_basic.column[0].priceOverTime) > 0 &&
          this.InfoContent.sale_basic.column[0].fromTimeRelease <=
            moment(String(new Date())).format("YYYY-MM-DD HH:mm") &&
          this.InfoContent.sale_basic.column[0].toTimeRelease >=
            moment(String(new Date())).format("YYYY-MM-DD HH:mm")
        ) {
          this.InfoContent.product_price = parseInt(
            this.InfoContent.sale_basic.column[0].priceOverTime
          );
        }
        // this.InfoContent.totalPrice =
        //   this.InfoContent.product_price / (1 + tax * 0.01);
        // this.InfoContent.tax =
        //   this.InfoContent.product_price - this.InfoContent.totalPrice;
        this.InfoContent.fee = this.InfoContent.sale_basic.column[0].fee
          ? this.InfoContent.sale_basic.column[0].fee
          : this.InfoContent.consultation_fee_default;
        if (
          this.InfoContent.sale_basic.column[0].sale_format ==
          this.paymentType.subscription
        ) {
          const bill_internal =
            this.InfoContent.sale_basic.column[0].billInternal;
          this.infoSubription.isSubscription = true;
          this.infoSubription.initialCost =
            this.InfoContent.sale_basic.column[0].initialCost;
          this.infoSubription.trialPeriod = this.InfoContent.sale_basic
            .column[0].trialPeriod
            ? this.InfoContent.sale_basic.column[0].trialPeriod
            : 0;
          this.infoSubription.addBill =
            this.InfoContent.sale_basic.column[0].addBill;
          this.infoSubription.billingCount =
            this.InfoContent.sale_basic.column[0].billingCount;
          this.infoSubription.textChange =
            bill_internal == 1
              ? "日"
              : bill_internal == 2
              ? "週"
              : bill_internal == 3
              ? "ヶ月"
              : "年";
        }
        if (this.InfoContent.product_type == 3)
          this.InfoContent.product_price = 0;
        this.InfoContent.totalPricePayment = this.InfoContent.fee
          ? parseInt(this.InfoContent.product_price) +
            parseInt(this.InfoContent.fee)
          : parseInt(this.InfoContent.product_price);
        this.InfoContent.tax = tax * 0.01 * this.InfoContent.totalPricePayment;
        this.$store.commit("set", ["modalShow", false]);
        this.htmlTerms = this.detailcontentPreSales[0].terms;
        this.htmlPolicy = this.detailcontentPreSales[0].policy;
      }
    },
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        localStorage.setItem(Constants.SHOP_ID, this.dataShop[0].shop_id);
        if (this.dataShop[0].logo != null) {
          this.logoShop = Constants.URL_BE + "/" + this.dataShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
      }
    },
    // InfoContentPayment() {
    //   this.InfoContent = this.InfoContentPayment;
    //   this.fee = this.InfoContentPayment.shipping;
    //   this.PriceContent = Math.floor(
    //     this.InfoContentPayment.amount /
    //       (1 + this.InfoContentPayment.tax_rate * 0.01)
    //   );
    //   this.TotalPriceContent = Math.floor(
    //     this.InfoContentPayment.amount + this.fee
    //   );
    //   this.tax = Math.floor(this.InfoContentPayment.amount - this.PriceContent);
    //   this.htmlTerms = this.InfoContentPayment.author.terms;
    //   this.htmlPolicy = this.InfoContentPayment.author.policy;
    // },
    dataGeneral() {
      if (this.dataGeneral) {
        this.isCheckFacebook = this.dataGeneral.facebook_status;
        this.isCheckLine = this.dataGeneral.line_status;
        this.isCheckTwitter = this.dataGeneral.twitter_status;
        this.isCheckAmazon = this.dataGeneral.amazone_status;
      }
    },
    detailStore() {
      if (
        this.detailStore &&
        this.detailStore[0] &&
        this.detailStore[0].duplicate
      ) {
        if (this.detailStore[0].url_redirect) {
          window.location.href = this.detailStore[0].url_redirect;
          return;
        } else {
          this.$router.push({
            name: this.$route.params.shopId ? "library" : "library domain",
          });
        }
      } else {
        if (this.is_login == false) {
          this.$router.push({
            name: this.$route.params.shopId
              ? "paymentBuy"
              : "paymentBuy domain",
            params: {
              shopId: this.$route.params.shopId,
              id: this.$route.params.idContent,
            },
            query: {
              urlSale: true,
            },
          });
        } else {
          this.$router.push({
            name: this.$route.params.shopId
              ? "paymentBuy"
              : "paymentBuy domain",
            params: {
              shopId: this.$route.params.shopId,
              id: this.$route.params.idContent,
            },
          });
        }
      }
    },
  },
  methods: {
    ...mapActions({
      setPageId: "setPageId",
      getListSettingShopUser: "getListSettingShopUser",
      getInforContentPayment: "getInforContentPayment",
      getGeneralByIdContent: "getGeneralByIdContent",
      getDetailContentPreSales: "getDetailContentPreSales",
      getShopById: "getShopById",
      getDetailContentPayment: "getDetailContentPayment",
    }),
    setAlert() {
      this.alertMessage = true;
    },
    async submitLogin() {
      const ShopID = this.detailShop.id;
      if (!this.email && !this.password) return;
      this.email = this.email ? this.email.trim() : "";
      this.password = this.password ? this.password.trim() : "";
      this.isLoading = true;
      const formLogin = {
        email: this.email,
        password: this.password,
        shop_id: ShopID,
        remember_me: this.remember_me,
      };
      if (this.password !== "" || this.email !== "") {
        Api.userRequestServer
          .post(`/login`, formLogin)
          .then((response) => {
            const { data } = response;
            if (data.success) {
              const { access_token, user, expires_in } = data.data;
              const { user_type } = user;
              const expires_at = new Date(
                new Date().setMinutes(new Date().getMinutes() + expires_in)
              );
              if (user_type != 4) {
                this.message =
                  "メールアドレスまたはパスワードが正しくありません。";
                this.setAlert();
              } else {
                localStorage.setItem(Constants.TOKEN_USER, access_token);
                localStorage.setItem(Constants.USER_TYPE_USER, user_type);
                localStorage.setItem(Constants.EXPIRES_AT_USER, expires_at);
                localStorage.setItem(Constants.USER_ID, data.data.user.user_id);
                localStorage.setItem(
                  Constants.EMAIL_USER,
                  data.data.user.email
                );
                localStorage.setItem("popupSend", 1);
                localStorage.setItem(
                  Constants.NORMAL_USER_INFO,
                  JSON.stringify(user)
                );
                localStorage.setItem(
                  Constants.IS_REMEMBER_USER,
                  this.remember_me
                );
                if (parseInt(this.InfoContent.totalPricePayment) === 0) {
                  this.$router.push({
                    name: this.$route.params.shopId
                      ? "content detail"
                      : "content detail domain",
                    params: {
                      shopId: ShopID,
                      id: this.$route.params.idContent,
                    },
                  });
                } else {
                  const id = this.$route.params.idContent;
                  this.getDetailContentPayment({
                    shopId: ShopID,
                    id,
                  });
                  // this.$router.push({
                  //   name: this.$route.params.shopId
                  //     ? "paymentBuy"
                  //     : "paymentBuy domain",
                  //   params: {
                  //     shopId: ShopID,
                  //     id: this.$route.params.idContent,
                  //   },
                  // });
                }
              }
            } else {
              this.message = data.message;
              this.setAlert();
              setTimeout(() => (this.alertMessage = false), 5000);
            }
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
          });
      } else {
        this.isLoading = false;
        this.message = "メールアドレスまたはパスワードを入力してください";
        this.setAlert();
        setTimeout(() => (this.alertMessage = false), 5000);
      }
    },
    loginFacebook() {
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/facebook?shop_id=${dataRequest.shop_id}&slag_token=${
            dataRequest.slag_token
          }&isLogin=${1}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginTwitter() {
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/twitter?shop_id=${dataRequest.shop_id}&slag_token=${
            dataRequest.slag_token
          }&isLogin=${1}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginLine() {
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/line?shop_id=${dataRequest.shop_id}&slag_token=${
            dataRequest.slag_token
          }&isLogin=${1}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loginAmazon() {
      const dataRequest = {
        shop_id: this.shop_id,
        slag_token: this.slagToken,
      };
      Api.userRequestServer
        .get(
          `/auth/amazon?shop_id=${dataRequest.shop_id}&slag_token=${
            dataRequest.slag_token
          }&isLogin=${1}`
        )
        .then((response) => {
          const { data } = response;
          window.location.href = `${data}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showPassword() {
      this.isshowPassword = !this.isshowPassword;
    },
  },
};
</script>
<style lang="scss">
.beforeFormLogin {
  width: 80% !important;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.8) !important;
  padding: 20px;
  border-radius: 10px;
  @media (max-width: 420px) {
    width: 80% !important;
  }
  @media (min-width: 1200px) {
    width: 1020px !important;
  }
}
.customTitle {
  background: #424b55;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 1;
  margin: 0px;
}
.img-logo-header {
  width: 286px;
  height: 79px;
}
.customTitleContent {
  color: #3c434a;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.4;
  font-weight: bold;
  font-size: 18px;
}
.customAmountContent {
  font-size: 23px;
  text-align: right;
  padding: 7px 0;
  padding-right: 14px;
}
.totalAmount {
  /* float: right; */
  text-align: right;
  font-size: 23px;
}
.title2 {
  color: #72777c;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  margin-top: 2em !important;
  margin-bottom: 1em !important;
  display: flex;
  align-items: center;
}
.title2::before,
.title2::after {
  border-top: 2px solid;
  content: "";
  flex-grow: 1;
}
.title2:before {
  margin-right: 0.5rem;
}
.title2:after {
  margin-left: 0.5rem;
}
</style>
